import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { bem } from 'lib/bem';
// eslint-disable-next-line import/no-cycle
import { Button, confirm, Page, PageActions, processModal } from 'lib/ui';
import { ApiSendResponse } from 'shared/api/types/api-methods-types';
import Decline from 'lib/i18n/Decline';
import { ApiActivitiesSchemasIssueTypeListResponseSchema } from 'shared/api/v4/swagger/data-contracts';
import BusyIndicator from 'lib/ui/BusyIndicator/BusyIndicator';
import { t } from 'lib/i18n';
import {
  DndItem,
  DndItemAction,
  DndItemFlat,
  DndProvider,
  DragEndItem,
} from 'shared/ui/dnd-provider';
import { ContentBlock } from 'widgets/content-block';
import { ActionsBlock } from 'widgets/actions-block';
import { SearchField } from 'widgets/search';
import { activitiesQueries, useDeleteActivity, useUpdateActivity } from 'entities/activities';
import { EmptyBlock } from 'shared/ui/empty-block';
import { queryClient } from 'shared/lib/react-query';

import { ActivitiesSettingsDialog } from './ActivitiesSettingsDialog';
import './ActivitiesSettings.scss';

type ActivityItemData = {
  name: string;
  icon_url?: string;
};

const { block } = bem('ActivitiesSettingsPage');

export const ActivitiesSettingsPage = () => {
  const { data: activitiesResponse, isLoading } = useQuery(activitiesQueries.list);
  const [open, setOpen] = useState(false);
  const [activityFilter, setActivityFilter] = useState<string>('');
  const [activityId, setActivityId] = useState<number | null>(null);

  const handleFilterChange = (value: string) => {
    setActivityFilter(value);
  };

  const activities = useMemo(() => {
    const activitiesData =
      activitiesResponse && 'response' in activitiesResponse
        ? activitiesResponse.response.data
        : undefined;
    if (activityFilter && activitiesData) {
      return activitiesData.filter((activity) =>
        activity.name.toLowerCase().includes(activityFilter),
      );
    }
    return activitiesData;
  }, [activitiesResponse, activityFilter]);

  const activity = activities?.find((it) => it.id === activityId);

  const data = useMemo<DndItem<ActivityItemData>[]>(
    () =>
      activities?.map((it) => ({
        ...it,
        itemData: {
          icon_url: it.icon_url,
          name: it.name,
          type: it.type,
        },
      })) ?? [],
    [activities],
  );

  const hasLinkedIssueTypes = async (id) => {
    const issueTypesResponse = await queryClient.fetchQuery(activitiesQueries.issueTypes(id));

    const issueTypesData =
      issueTypesResponse as ApiSendResponse<ApiActivitiesSchemasIssueTypeListResponseSchema>;
    if (issueTypesData && 'response' in issueTypesData) {
      return issueTypesData.response?.data;
    }
    return null;
  };

  const { data: issueTypesResponse } = useQuery({
    ...activitiesQueries.issueTypes(activity?.id),
    enabled: !!activity?.id,
  });

  const issueTypes = useMemo(() => {
    const issueTypesData =
      issueTypesResponse as ApiSendResponse<ApiActivitiesSchemasIssueTypeListResponseSchema>;
    if (issueTypesData && 'response' in issueTypesData) {
      return issueTypesData.response?.data;
    }
    return null;
  }, [issueTypesResponse]);

  const deleteMutation = useDeleteActivity();
  const { mutateAsync: updateMutation } = useUpdateActivity();

  const handleDialogClose = useCallback(() => {
    setOpen(false);
    setActivityId(null);
  }, []);

  const handleEdit = useCallback((id: number) => {
    setOpen(true);
    setActivityId(id);
  }, []);

  const handleAdd = () => {
    setOpen(true);
    setActivityId(null);
  };

  const handleDelete = useCallback(
    async (id: number) => {
      setActivityId(id);
      const linkedIssueTypes = await hasLinkedIssueTypes(id);
      const relatedIssueTypesNumber = linkedIssueTypes?.length;
      const relatedIssueTypesIds = linkedIssueTypes?.map((it) => it.id) ?? [];
      if (linkedIssueTypes && relatedIssueTypesNumber) {
        // TODO: remake warning popup
        const showRestrictedWarning = processModal({
          getTitle: () => t('activities.pages.editor.remove_restricted_title'),
        })(() => (
          <Trans
            i18nKey="activities.pages.editor.remove_restricted_text"
            components={{
              decline: <Decline number={relatedIssueTypesNumber} />,
              issueTypesLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                <a
                  href={`/settings/issue_types/?issue_type_ids=${relatedIssueTypesIds.join(',')}`}
                />
              ),
            }}
            values={{ relatedIssueTypesNumber }}
          />
        ));
        showRestrictedWarning();
      } else {
        const result = await confirm(t('activities.pages.editor.remove_confirmation'), {
          proceedText: t('common.remove'),
        });
        if (result) {
          deleteMutation.mutate(Number(id));
        }
      }
    },
    [deleteMutation],
  );

  const handleDragEnd = ({ itemId, beforeId }: DragEndItem<ActivityItemData>) => {
    const item = activities?.find((it) => it.id === itemId);

    updateMutation({
      activityId: Number(itemId),
      item: {
        name: item?.name ?? '',
        icon_url: item?.icon_url ?? '',
        type: item?.type ?? '',
        before_id: beforeId != null ? Number(beforeId) : (null as unknown as undefined),
        params: item?.params,
        managed_objects: item?.managed_objects,
        managed_object_types: item?.managed_object_types,
      },
    });
  };

  const getActions = useCallback(
    (item: DndItemFlat<ActivityItemData>): DndItemAction[] => {
      return [
        {
          name: 'Edit',
          onClick: () => handleEdit(Number(item.id)),
          icon: 'pen',
        },
        {
          name: 'Remove',
          onClick: () => handleDelete(Number(item.id)),
          icon: 'delete',
        },
      ];
    },
    [handleEdit, handleDelete],
  );

  return (
    <>
      <Page
        {...block()}
        title={t('activities.pages.editor.title')}
        actions={
          <PageActions>
            <Button onClick={handleAdd}>{t('common.add')}</Button>
          </PageActions>
        }
      >
        <SearchField onChange={handleFilterChange} />
        {activities?.length === 0 && <EmptyBlock textKey="common.no_results" />}

        {isLoading ? (
          <BusyIndicator sx={{ mt: 1 }} />
        ) : (
          <DndProvider
            data={data}
            draggable
            onDragEnd={handleDragEnd}
            getActions={getActions}
            ContentComponent={ContentBlock}
            ActionsComponent={ActionsBlock}
          />
        )}
      </Page>
      {open && (
        <ActivitiesSettingsDialog
          issueTypes={issueTypes}
          activity={activity}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
};
