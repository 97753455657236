import { ComponentType, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import { usersService } from 'app/users';
import users, { isCrossDomainAuth } from 'app/users/users.resource';
import { useAuthContext } from 'app/providers';
import { RouteInfo } from 'config/routesMap';
import { saveLocalStorageItem } from 'lib/helpers/localStorage';

export const withUserAccessCheck = (route: RouteInfo, Component: ComponentType) => {
  const UserAccessCheck = (props) => {
    const { userData } = useAuthContext();
    const { pathname, search } = useLocation();
    const { issueList } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      (async () => {
        try {
          const signinToken = isCrossDomainAuth();
          if (signinToken && route.path === '/login') {
            saveLocalStorageItem('authToken', signinToken);

            const response = await users.current.request();
            if (response.isGuest) {
              navigate('/login', { replace: true });
            } else {
              navigate(`/${window.location.search}`, { replace: true });
            }
          }

          if (!userData) {
            return; // TODO: Что тут должно быть?
          }

          if (!usersService.userHasAccessToRoute(userData, route) && !signinToken) {
            navigate(
              userData.isGuest
                ? `/${route.loginPath || 'login'}?redirect=${encodeURIComponent(
                    `${pathname}${search}`,
                  )}`
                : '/',
              { replace: true },
            );
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }, [navigate, pathname, search, userData]);

    useEffect(() => {
      if (
        issueList &&
        userData?.web_configuration?.cabinet.lists.findIndex((list) => list.id === issueList) === -1
      ) {
        navigate('/');
      }
    }, [issueList, navigate, userData?.web_configuration?.cabinet.lists]);

    return isLoading ? null : <Component {...props} />;
  };

  return UserAccessCheck;
};
